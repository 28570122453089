import { ask } from '@lighthouse/hall-pass'
import { find, get, stubFalse, transform } from 'lodash'

// TODO inherit link path

const getNavigationLabel = ({ parent, key }) => {
  if (parent) {
    return `navigation.${parent}.${key}`
  }

  return `navigation.${key}`
}

export const navigation = {
  maps: {
    link: '',
    label: 'Maps',
    labelT: getNavigationLabel({ key: 'maps' }),
    index: true,
    predicate: validateModulePermissions('maps'),
  },
  schedules: {
    link: '/schedules',
    label: 'Schedules',
    labelT: getNavigationLabel({ key: 'schedules' }),
    predicate: validateModulePermissions('schedule'),
    children: {
      work: {
        link: '/schedules/work',
        label: 'Work',
        labelT: getNavigationLabel({ key: 'work', parent: 'schedules' }),
        order: 1,
        predicate: alwaysVisible,
      },
    },
  },
  setup: {
    link: '/setup/locations',
    label: 'Setup',
    labelT: getNavigationLabel({ key: 'setup' }),
    predicate: validateModulePermissions('navSetup'),
    children: {
      areas: {
        link: '/setup/areas',
        label: 'Locations',
        labelT: getNavigationLabel({ parent: 'setup', key: 'locations' }),
        order: 1,
        predicate: validateAreasTabPredicate,
      },
      'location-groups': {
        link: '/setup/location-groups',
        label: 'Location Groups',
        labelT: getNavigationLabel({
          parent: 'setup',
          key: 'location-groups',
        }),
        order: 2,
        predicate: opts => validateAreasTabPredicate(opts),
      },
      signals: {
        link: '/setup/signals',
        label: 'Signals',
        labelT: getNavigationLabel({ parent: 'setup', key: 'signals' }),
        order: 3,
        predicate: validateModulePermissions('zone'),
      },
    },
  },
  templates: {
    link: '/templates/content',
    label: 'Templates',
    labelT: getNavigationLabel({ key: 'templates' }),
    predicate: alwaysVisible,
    children: {
      content: {
        link: '/templates/content',
        label: 'Content Templates',
        labelT: getNavigationLabel({ parent: 'templates', key: 'content' }),
        order: 1,
        predicate: opts =>
          validateModulePermissions('contentTemplate', 'create')(opts) &&
          hasFlag(opts, 'content'),
      },
      entries: {
        link: '/templates/entries',
        label: 'Content Entries',
        labelT: getNavigationLabel({ parent: 'templates', key: 'entries' }),
        order: 2,
        predicate: opts =>
          validateModulePermissions('contentEntry', 'create')(opts) &&
          hasFlag(opts, 'content'),
      },
      tasks: {
        link: '/templates/tasks',
        label: 'Tasks',
        labelT: getNavigationLabel({ parent: 'templates', key: 'tasks' }),
        order: 3,
        predicate: validateModulePermissions('template', 'read'),
      },
      audits: {
        link: '/templates/audits',
        label: 'Audits',
        labelT: getNavigationLabel({ parent: 'templates', key: 'audits' }),
        order: 4,
        predicate: validateModulePermissions('template', 'read'),
      },
      issues: {
        link: '/templates/issues',
        label: 'Issues',
        labelT: getNavigationLabel({ parent: 'templates', key: 'issues' }),
        order: 5,
        predicate: validateModulePermissions('template', 'read'),
      },
    },
  },
  reports: {
    link: '/reports/events',
    label: 'Reports',
    labelT: getNavigationLabel({ key: 'reports' }),
    predicate: alwaysVisible,
    children: {
      dashboard: {
        link: '/reports/dashboard',
        label: 'Dashboard',
        labelT: getNavigationLabel({ parent: 'reports', key: 'dashboard' }),
        order: 1,
        predicate: validateModulePermissions('navDashboard'),
      },
      events: {
        link: '/reports/events',
        label: 'Events',
        labelT: getNavigationLabel({ parent: 'reports', key: 'events' }),
        order: 4,
        predicate: validateModulePermissions('event'),
      },
      exceptions: {
        link: '/reports/exceptions',
        label: 'Exceptions',
        labelT: getNavigationLabel({ parent: 'reports', key: 'exceptions' }),
        order: 5,
        predicate: validateModulePermissions('loopException'),
      },
      tasks: {
        link: '/reports/tasks',
        label: 'Tasks',
        labelT: getNavigationLabel({ parent: 'reports', key: 'tasks' }),
        order: 6,
        predicate: validateModulePermissions('taskEntry'),
      },
      audits: {
        link: '/reports/audits',
        label: 'Audits',
        labelT: getNavigationLabel({ parent: 'reports', key: 'audits' }),
        order: 7,
        predicate: validateModulePermissions('auditEntry'),
      },
      issues: {
        link: '/reports/issues',
        label: 'Issues',
        labelT: getNavigationLabel({ parent: 'reports', key: 'issues' }),
        order: 8,
        predicate: validateModulePermissions('issue'),
      },
      locations: {
        link: '/reports/locations',
        label: 'Locations',
        labelT: getNavigationLabel({ parent: 'reports', key: 'locations' }),
        order: 9,
        predicate: opts =>
          validateModulePermissions('locationReport')(opts) &&
          (hasFlag(opts, 'dailyLocationReports') || isAdminUser(opts)),
      },
      shifts: {
        link: '/reports/shifts',
        label: 'Shifts',
        labelT: getNavigationLabel({ parent: 'reports', key: 'shifts' }),
        order: 10,
        predicate: validateModulePermissions('shift'),
      },
      exports: {
        link: '/reports/exports',
        label: 'Exports',
        labelT: getNavigationLabel({ parent: 'reports', key: 'exports' }),
        order: 12,
        predicate: validateModulePermissions('export'),
      },
    },
  },
  messages: {
    link: '/messages',
    label: 'Messages',
    labelT: getNavigationLabel({ key: 'messages' }),
    predicate: validateModulePermissions('messages'),
  },
  admin: {
    link: '/admin',
    label: 'Admin',
    labelT: getNavigationLabel({ key: 'admin' }),
    predicate: stubFalse,
    children: {
      applications: {
        link: '/admin/applications',
        label: 'Applications',
        labelT: getNavigationLabel({ parent: 'admin', key: 'applications' }),
        order: 0,
        predicate: alwaysVisible,
      },
      flags: {
        link: '/admin/flags',
        label: 'Flags',
        order: 1,
        predicate: alwaysVisible,
      },
    },
  },
  settings: {
    link: '/settings/profile',
    label: 'Settings',
    labelT: getNavigationLabel({ key: 'settings' }),
    predicate: () => false,
    children: {
      users: {
        order: 0,
        label: 'Users',
        labelT: getNavigationLabel({ parent: 'settings', key: 'users' }),
        link: '/settings/users',
        predicate: validateModulePermissions('applicationUser'),
      },
      roles: {
        order: 1,
        label: 'Roles & Permissions',
        labelT: getNavigationLabel({ parent: 'settings', key: 'roles' }),
        link: '/settings/roles',
        predicate: validateModulePermissions('role'),
      },
      profile: {
        order: 2,
        label: 'Profile',
        labelT: getNavigationLabel({ parent: 'settings', key: 'profile' }),
        link: '/settings/profile',
        predicate: alwaysVisible,
      },
    },
  },
}

export function generateDeprecated(opts = {}) {
  const { slice } = opts

  const navSlice = slice ? get(navigation, slice) : navigation
  const navItems = slice ? navSlice.children : navigation

  return transform(navItems, (result, definition, key) => {
    const { index, link, label, labelT, predicate, children } = definition

    const predicateResult = predicate(opts)

    // if children, obtain link by iterating through children and
    // finding the first that passes the predicate

    let itemLink = link

    if (children) {
      const firstValidChild = find(children, child => child.predicate(opts))
      // if there are no valid children for this nav item, there is no point
      // including it
      if (!firstValidChild) return

      // otherwise, set the link of the parent to the first valid child
      itemLink = firstValidChild.link
    }

    if (predicateResult) {
      result[key] = {
        index,
        label,
        labelT,
        link: itemLink,
      }
    }
  })
}

export function generate(opts = {}) {
  const { slice } = opts
  console.info('Tab Persistence True')
  const navSlice = slice ? get(navigation, slice) : navigation
  const navItems = slice ? navSlice.children : navigation

  const result = {}

  Object.entries(navItems).forEach(([key, definition]) => {
    const { index, link, label, labelT, predicate } = definition

    const predicateResult = predicate(opts)

    if (predicateResult) {
      result[key] = {
        index,
        label,
        labelT,
        link,
      }
    }
  })

  return result
}

function alwaysVisible() {
  return true
}

function hasFlag(opts = {}, flag) {
  const { flags = {} } = opts
  return flags[flag]
}

function validateAreasTabPredicate(opts) {
  const { permissions } = opts

  const hasAreaModuleEnabled = validateModulePermissions('area')({
    permissions,
  })

  return hasAreaModuleEnabled
}

function validateModulePermissions(module, permission = 'read') {
  return opts => {
    const withOpts = { module }

    // NOTE create permissions require a body object to check against, so in
    // those scenarios we'll mock it out so it doesn't always fail
    if (permission === 'create') {
      withOpts.body = {}
    }

    const result = ask(opts.permissions, permission, withOpts)

    return !!result
  }
}

function isAdminUser(opts) {
  const userEmail = get(opts, 'user.email')
  return userEmail === 'support@lighthouse.io'
}
